import Swiper from "swiper";

function officialSuitSwiper() {
  const swiperSlides = document.getElementsByClassName("swiper-slide");

  const breakPoint = 768;
  let mySwiper: Swiper;
  let mySwiperBool: boolean;

  window.addEventListener(
    "load",
    () => {
      if (breakPoint < window.innerWidth) {
        mySwiperBool = false;
      } else {
        createSwiper();
        mySwiperBool = true;
      }
    },
    false,
  );

  window.addEventListener(
    "resize",
    () => {
      if (breakPoint < window.innerWidth && mySwiperBool) {
        mySwiper.destroy(false, true);
        mySwiperBool = false;
      } else if (breakPoint >= window.innerWidth && !mySwiperBool) {
        createSwiper();
        mySwiperBool = true;
      }
    },
    false,
  );

  const createSwiper = () => {
    mySwiper = new Swiper(".js-slider", {
      speed: 800,
      slideToClickedSlide: true,
      loop: true,
      loopedSlides: swiperSlides.length,
      centeredSlides: true,
      slidesPerView: 1.27,
      spaceBetween: 10,
      autoplay: {
        delay: 2000,
      },
    });
  };
}

export { officialSuitSwiper };
