function Order() : void{
  const accordionWrapper = document.querySelectorAll(".js-accordion");
  const accordionWrapperArr = Array.prototype.slice.call(accordionWrapper);  
  const pcw = document.getElementsByClassName("p-order-detail__item")
  const pcwArr = Array.prototype.slice.call(pcw);  
    for (let i = 0; i < accordionWrapperArr.length; i++) {
      if (accordionWrapperArr) {
        
        accordionWrapperArr[i].addEventListener('click', function() {
          if (accordionWrapperArr[i].classList.contains("is-open")) {
            if (matchMedia('only screen and (max-width: 767px)').matches) {          
              accordionWrapperArr[i].classList.remove("is-open");   
                       
            } else {
              accordionWrapperArr[i].classList.remove("is-open");   
              for (let i = 0; i < pcwArr.length; i++) {
                pcwArr[i].classList.remove("is-open-pc");
              }
            }
          } else {
            if (matchMedia('only screen and (max-width: 767px)').matches) {          
              accordionWrapperArr[i].classList.add("is-open");                                     
            } else {              
              for (let i = 0; i < pcwArr.length; i++) {                
                pcwArr[i].classList.remove("is-open");                
                pcwArr[i].classList.add("is-open-pc");                
              }
              accordionWrapperArr[i].classList.add("is-open");  
              accordionWrapperArr[i].classList.remove("is-open-pc");
            }
          }
        })
      };    
    }
  };    

  /**
 * アコーディオンメニュー開閉
 */

function orderAccordion(): void {
  const trigger = document.querySelectorAll(".js-accordion-trigger");
  const triggerArr = Array.prototype.slice.call(trigger);
  for (let i = 0; i < triggerArr.length; i++) {
    triggerArr[i].addEventListener("click", function() {
      const parent = triggerArr[i].parentNode;
      const sosen = triggerArr[i].parentNode.parentNode;
      const body = parent.nextElementSibling;
      if (triggerArr[i].classList.contains("is-accordion-active")) {
        triggerArr[i].classList.remove("is-accordion-active");
        triggerArr[i].setAttribute("title", "メニューを開く");
        triggerArr[i].setAttribute("aria-selected", false);
        triggerArr[i].setAttribute("aria-expanded", false);
        sosen.classList.remove("is-accordion-list-active");
        body.classList.remove("is-accordion-open");
        body.setAttribute("aria-hidden", true);
        body.setAttribute("tabindex", -1);
      } else {
        triggerArr[i].classList.add("is-accordion-active");
        triggerArr[i].setAttribute("title", "メニューを閉じる");
        triggerArr[i].setAttribute("aria-selected", true);
        triggerArr[i].setAttribute("aria-expanded", true);
        sosen.classList.add("is-accordion-list-active");
        body.classList.add("is-accordion-open");
        body.setAttribute("aria-hidden", false);
        body.setAttribute("tabindex", 0);
      }
    });
  }
}
  
  /**
 * よくある質問ヘルプマークマウスオンムーブ
 */

function orderAccordionHelper(): void {
  const mousetrigger = document.querySelectorAll(".js-mousehover");
  const mousetriggerArr = Array.prototype.slice.call(mousetrigger);
  for (let i = 0; i < mousetriggerArr.length; i++) {
    const modalWrapper = mousetriggerArr[i].nextElementSibling;
    mousetriggerArr[i].addEventListener("mouseover", function() {            
      modalWrapper.classList.add("is-mouse-active");                     
    }, false);
    
    mousetriggerArr[i].addEventListener("mouseleave", function() {            
      modalWrapper.classList.remove("is-mouse-active");                     
    }, false);
  }
}


function orderDatawidth(): void {  
  const bertrigger = document.querySelectorAll(".p-order-style__styleBer-line");  
  const triangletrigger = document.querySelectorAll(".p-order-style__styleBer-triangle");  
  const bertriggerArr = Array.prototype.slice.call(bertrigger);  
  const triangleArr = Array.prototype.slice.call(triangletrigger);  
  for (let i = 0; i < bertriggerArr.length; i++) {
    const data = bertriggerArr[i].dataset.width;    
    bertriggerArr[i].style.width = data;
    for (let i = 0; i < triangleArr.length; i++) {
      const data2 = bertriggerArr[i].dataset.width;    
      triangleArr[i].style.left = data2;
      // triangleArr[i].style.transform = "translateX(-" + data2 + ")";
    }
  }
}
  
export { Order, orderAccordion, orderAccordionHelper, orderDatawidth };
