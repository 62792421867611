import { contentsSlider } from "./namespace/contentsSlider";
import { fadeInElementsOnViewport } from "./namespace/fadeInElementsOnViewport";
import { initializeTabSwitching } from "./namespace/initializeTabSwitching";

function anniversary25th() {
  const contentsCarousel = document.querySelector("#contentsCarousel");
  if (contentsCarousel) {
    contentsSlider();
  }
  fadeInElementsOnViewport();
  initializeTabSwitching();
}

export { anniversary25th };
