function modalHandler() {
  document.addEventListener("DOMContentLoaded", function () {
    const modalList = Array.from(document.querySelectorAll(".js-gallery-image"));
    const triggerImageList = document.querySelectorAll(".js-image-unit");

    const modal = document.getElementById("modal");
    const modalImagePrimary = document.querySelector(".js-modal-image-primary");
    const modalImageSecondary = document.querySelector(".js-modal-image-secondary");
    const closeModal = document.getElementById("close-modal");
    const prevButton = document.getElementById("modal-prev");
    const nextButton = document.getElementById("modal-next");

    let currentImageIndex = 0;

    triggerImageList.forEach((image, index) => {
      image.addEventListener("click", function (e) {
        modal?.setAttribute("aria-hidden", "false");
        const targetImgIndex = image.querySelector("img")!.getAttribute("data-index");
        const modalTargetElement = modalList[Number(targetImgIndex) - 1];
        const primaryImg = modalTargetElement.querySelector("img");
        const secondaryImg = modalTargetElement.querySelector("img")?.nextSibling;
        (modalImagePrimary as HTMLImageElement).src = (primaryImg as HTMLImageElement).src;
        (modalImageSecondary as HTMLImageElement).src = (secondaryImg as HTMLImageElement).src;
        currentImageIndex = index;
      });
    });

    const updateModalImages = () => {
      const modalTargetElement = modalList[currentImageIndex];
      const primaryImg = modalTargetElement.querySelector("img");
      const secondaryImg = primaryImg?.nextSibling; // nextSiblingは存在することを前提とします

      (modalImagePrimary as HTMLImageElement).src = (primaryImg as HTMLImageElement).src;
      (modalImageSecondary as HTMLImageElement).src = (secondaryImg as HTMLImageElement).src;
    };

    closeModal?.addEventListener("click", function () {
      modal?.setAttribute("aria-hidden", "true");
    });

    prevButton?.addEventListener("click", function () {
      if (currentImageIndex === 0) {
        currentImageIndex = modalList.length - 1;
      } else {
        if (currentImageIndex > 0) {
          currentImageIndex--;
        }
      }
      updateModalImages();
    });

    nextButton?.addEventListener("click", function () {
      if (currentImageIndex === modalList.length - 1) {
        currentImageIndex = 0;
      } else {
        if (currentImageIndex < modalList.length - 1) {
          currentImageIndex++;
        }
      }
      updateModalImages();
    });
  });
}

export { modalHandler };
