const Shop = () => {
  const shopUnitElemList = document.querySelectorAll<HTMLElement>(".js-shop-unit");
  const navItemElemList = document.querySelectorAll<HTMLElement>(".js-nav-item");

  const targetActiveElem = (navItem: HTMLElement) => {
    const navItemName = navItem.getAttribute("aria-label");
    shopUnitElemList.forEach((shopUnitElem) => {
      const shopUnitName = shopUnitElem.getAttribute("id");
      if (navItemName === shopUnitName) {
        shopUnitElem.setAttribute("aria-hidden", "false");
        navItem.setAttribute("aria-selected", "true");
      }
      if (navItemName === "all") {
        shopUnitElem.setAttribute("aria-hidden", "false");
        navItem.setAttribute("aria-selected", "true");
      }
    });
  };

  const allInActiveElem = () => {
    navItemElemList.forEach((navItem) => {
      navItem.setAttribute("aria-selected", "false");
    });

    shopUnitElemList.forEach((shopUnitElem) => {
      shopUnitElem.setAttribute("aria-hidden", "true");
    });
  };

  navItemElemList.forEach((navItem) => {
    navItem.addEventListener("click", () => {
      // いったん全てを非アクティブ
      allInActiveElem();
      targetActiveElem(navItem);
    });
  });
};

export default Shop;
