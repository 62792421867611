/**
 * リンクの選択表示を制御する
 * @function currentLinkTab
 * @return {void}
 */
function currentLinkTab(): void {
  // const linkItemList = Array.from(document.querySelectorAll(".js-link-item"));
  // const tabList = Array.from(document.querySelectorAll(".js-link-item"));
  const linkItemList = Array.from(document.querySelectorAll(".js-tab-item"));
  const tabList = Array.from(document.querySelectorAll(".js-tab-item"));

  if (!tabList || !linkItemList) return;

  // pathname remove slash and get only string
  const pathnameBase = window.location.pathname.replace(/^\/|\/$/g, "");
  const pathname = pathnameBase.substring(pathnameBase.lastIndexOf('/') + 1)

  // 一旦全てのis-currentを削除
  for (const linkItem of linkItemList) {
    linkItem.classList.remove("is-current");
  }

  for (const linkItem of linkItemList) {
    const dataPathName = linkItem.getAttribute("data-pathname");
    dataPathName === pathname && linkItem.setAttribute("aria-selected", "true");
  }
}

export { currentLinkTab };
