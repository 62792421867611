import { getMediaQueryList } from "../util/getMediaQueryList";

function Top() {
  const fadeMv = () => {
    const mvBottomLinkList = document.querySelector(".p-top-mv__link.-pc");
    const mvBottomLinkListSP = document.querySelector(".p-top-mv__link.-sp");
    const mvCopy = document.getElementById("js-mv-copy");

    mvBottomLinkList && mvBottomLinkList.setAttribute("aria-hidden", "false");

    mvBottomLinkListSP && mvBottomLinkListSP.setAttribute("aria-hidden", "false");

    mvCopy && mvCopy.classList.add("is-show");
  };

  const logoVisibility = () => {
    const mql = window.matchMedia("(min-width: 768px)");
    const logoMain = document.getElementById("js-logo-main");

    // 画面ロードに条件（PCサイズ以上）に一致したらボトムメニューを表示する。一致しない場合は、監視を実行する。
    if (mql.matches) {
      logoMain?.setAttribute("aria-hidden", "false");
    } else {
      logoMain?.setAttribute("aria-hidden", "true");
    }

    getMediaQueryList("md").addEventListener("change", (event) => {
      if (event.matches) {
        logoMain?.setAttribute("aria-hidden", "false");
      } else {
        logoMain?.setAttribute("aria-hidden", "true");
      }
    });
  };

  logoVisibility();
  fadeMv();
}
export { Top };
