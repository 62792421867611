function initializeTabSwitching(): void {
  const tabButtons = Array.from(document.querySelectorAll('.p-25thanniversary-top-links a[role="tab"]'));
  const contentLists = Array.from(document.querySelectorAll(".p-25thanniversary-top-archive__list"));

  tabButtons.forEach((button) => {
    button.addEventListener("click", (event) => {
      event.preventDefault();
      const targetTab = (event.currentTarget as HTMLElement).dataset.tab;

      // 全てのコンテンツリストを非表示にする
      contentLists.forEach((list) => {
        const element = list as HTMLElement;
        if (element.dataset.content === targetTab) {
          element.removeAttribute("hidden");
          setTimeout(() => {
            element.classList.add("is-visible");
          }, 10); // 少し遅延させてからopacityを変更
        } else {
          element.classList.remove("is-visible");
          element.setAttribute("hidden", "until-found");
        }
      });

      // 全てのボタンからactiveクラスを削除し、aria-selectedをfalseにする
      tabButtons.forEach((button) => {
        button.classList.remove("active");
        button.setAttribute("aria-selected", "false");
      });

      // クリックされたボタンにactiveクラスを追加し、aria-selectedをtrueにする
      (event.currentTarget as HTMLElement).classList.add("active");
      (event.currentTarget as HTMLElement).setAttribute("aria-selected", "true");
    });
  });
}

export { initializeTabSwitching };
