import Swiper, { Navigation, Pagination, Autoplay, EffectFade, SwiperOptions } from "swiper";
import { changeStatusClass, initProgressFeatureAnimationDuration } from "../../lib/swiper";
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

export const Fabric = () => {
  let featureSlide: any;
  let SPEED = 5000;
  const SLIDE_CHANGE_DURATION = 300;

  const featureSwiperOptions: SwiperOptions = {
    speed: SLIDE_CHANGE_DURATION,
    slidesPerView: "auto",
    centeredSlides: true,
    loop: true,
    spaceBetween: 0,
    autoplay: {
      delay: SPEED,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: "auto",
        spaceBetween: 0,
      },
    },
    pagination: {
      el: ".p-order-fabric-feature__swiper-pagination",
      clickable: true,
      type: "bullets",
      renderBullet: function (index: number, className: string) {
        return (
          '<span class="' +
          className +
          " p-pagination-bullet-feature" +
          '">' +
          "<b class='c-swiper-progress-feature-in'>" +
          "</b>" +
          "</span>"
        );
      },
    },
    navigation: {
      nextEl: ".p-order-fabric-feature__swiper-button-next",
      prevEl: ".p-order-fabric-feature__swiper-button-prev",
    },
    on: {
      slideChange: (s) => {
        if (featureSlide) {
          featureSlide.params.autoplay.delay = SPEED - SLIDE_CHANGE_DURATION;
          changeStatusClass(s.realIndex);
        }
      },
      init: function () {
        const slides = document.querySelectorAll(".p-order-fabric-feature__card");
        slides.forEach((slide) => {
          slide.classList.add("changed");
        });
      },
      slideChangeTransitionStart: function () {
        const slides = document.querySelectorAll(".p-order-fabric-feature__card");
        slides.forEach((slide) => {
          slide.classList.add("changing");
          slide.classList.remove("changing");
        });
      },
      slideChangeTransitionEnd: function () {
        const slides = document.querySelectorAll(".p-order-fabric-feature__card");
        slides.forEach((slide) => {
          slide.classList.remove("changing");
          slide.classList.add("changing");
        });
      },
    },
  };

  // Featureスライド
  const featureSliderHandler = () => {
    featureSlide = new Swiper(".p-order-fabric-feature__body", featureSwiperOptions);
    initProgressFeatureAnimationDuration(SPEED);
  };

  featureSliderHandler();
};
