const fadeAnimation = () => {
  const targetElems = document.querySelectorAll(".u-fade-init");
  const targetElemsSecondary = document.querySelectorAll(".u-fade-out");

  let options = {
    threshold: 0.35,
  };

  const callback = function (entries: any) {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("u-fade-in");
      }
    });
  };

  const io = new IntersectionObserver(callback, options);

  if (!targetElems) return;
  targetElems.forEach((targetElem) => {
    io.observe(targetElem);
  });

  if (!targetElemsSecondary) return;
  targetElemsSecondary.forEach((targetElem) => {
    io.observe(targetElem);
  });
};

fadeAnimation();

export { fadeAnimation };
