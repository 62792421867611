/**
 * Breakpoint
 */

export const breakpoint = {
  xs: "0px",
  sm: "599px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  xxl: "1440px",
  xxxl: "1600px"
};
