/**
 * タブの選択表示を制御する
 * .js-tab-item と .js-tab-body がhtmlソース内に存在することが前提
 * @function tabSelect
 * @return {void}
 */
function tabSelect(): void {
  const tabList = Array.from(document.querySelectorAll(".js-tab-item"));
  const tabBodyList = Array.from(document.querySelectorAll(".js-tab-body"));

  if (!tabList || !tabBodyList) return;

  for (const tabItem of tabList) {
    tabItem.addEventListener("click", (e) => {
      for (const tabItem of tabList) {
        tabItem.setAttribute("aria-selected", "false");
      }
      (e.target as HTMLElement).closest("li")?.setAttribute("aria-selected", "true");

      const tabDataCategory = (e.target as HTMLElement).closest("li")?.getAttribute("data-category");
      console.log("tabBodyList=>", tabBodyList);
      for (const tabBodyItem of tabBodyList) {
        tabBodyItem.setAttribute("aria-hidden", "true");
        if (tabBodyItem.getAttribute("data-category") === tabDataCategory) {
          tabBodyItem.setAttribute("aria-hidden", "false");
        }
      }
    });
  }
}

export { tabSelect };
