import Splide from "@splidejs/splide";

function contentsSlider(): void {
  const splide = new Splide("#contentsCarousel", {
    perPage: 1,
    gap: "24px",
    padding: {
      right: "31.21%", // 768px以上の時の右側の余白
      left: "24px", // 768px以上の時の左側の余白
    },
    autoplay: true,
    arrows: true,
    pagination: false,
    speed: 1000, // スライドが変わる速度を1000ミリ秒（1秒）に設定
    breakpoints: {
      768: {
        padding: {
          right: "22.4%", // 次のスライドが見えるように右側に余白を追加
          left: "16px",
        },
        gap: "16px",
      },
    },
  });

  const arrows = document.querySelectorAll(".splide__arrow");

  splide.on("mounted updated", () => {
    const slideCount = splide.Components.Elements.slides.length;
    arrows.forEach((arrow) => {
      if (arrow instanceof HTMLElement) {
        if (slideCount <= 1) {
          arrow.style.display = "none";
        } else {
          arrow.style.display = "";
        }
      }
    });
  });

  splide.mount();
}

export { contentsSlider };
