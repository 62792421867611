import Swiper, { Navigation, Pagination, Autoplay, SwiperOptions, EffectFade } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

let featureSlide: any;
let mvSlide: any;
let SPEED = 5000;
let MV_SPEED = 5000;
const SLIDE_CHANGE_DURATION = 300;
const MV_SLIDE_CHANGE_DURATION = 800;

const mvSlideLength = document.querySelectorAll<HTMLElement>(".p-top-mv__swiper-slide").length;
const featureSlideLength = document.querySelectorAll<HTMLElement>(".c-feature__card").length;
const orderSuitStyleSlideLength = document.querySelectorAll<HTMLElement>(".p-order-suit-style__styleItem").length;
const orderModalSlideLength = document.querySelectorAll<HTMLElement>(".p-order-suit-style-modal__item").length;
const newsTickerLength = document.querySelectorAll<HTMLElement>(".l-header__announce-bar-slide").length;

// スライドが1枚の時にfalseを返す
const isSingle = (slideLength: number): boolean => (slideLength <= 2 ? false : true);

export const initProgressFeatureAnimationDuration = (speed: number) => {
  const inidicatorsIn = document.querySelectorAll(".c-swiper-progress-feature-in");
  inidicatorsIn.forEach((el: any, index) => {
    el.style.animationDuration = speed + "ms";
  });
};

const initProgressAnimationDuration = (speed: number) => {
  const inidicatorsIn = document.querySelectorAll(".c-swiper-progress-in");
  inidicatorsIn.forEach((el: any, index) => {
    el.style.animationDuration = speed + "ms";
  });
};

export const changeStatusClass = (rIdx: number) => {
  const indicators = document.querySelectorAll(".p-pagination-bullet-feature");
  indicators.forEach((el, index) => {
    rIdx > index && el.classList.add("is-completed"); // 現在のインデックス以前のものには「.is-completed」を付与
    rIdx < index && el.classList.remove("is-completed"); // 現在のインデックス以降のものは「.is-completed」を削除
  });
};

const swiperOptions: SwiperOptions = {
  speed: SLIDE_CHANGE_DURATION,
  // slidesPerView: 1.75,
  slidesPerView: "auto",
  centeredSlides: true,
  loop: isSingle(featureSlideLength),
  spaceBetween: 0,
  autoplay: {
    delay: SPEED,
    disableOnInteraction: false,
  },
  // autoplay: false,
  breakpoints: {
    768: {
      slidesPerView: "auto",
      spaceBetween: 0,
    },
  },
  pagination: {
    el: ".c-feature__swiper-pagination",
    clickable: true,
    type: "bullets",
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        " p-pagination-bullet-feature" +
        '">' +
        "<b class='c-swiper-progress-feature-in'>" +
        "</b>" +
        "</span>"
      );
    },
  },
  navigation: {
    nextEl: ".c-feature__swiper-button-next",
    prevEl: ".c-feature__swiper-button-prev",
  },
  on: {
    slideChange: (s) => {
      if (featureSlide) {
        featureSlide.params.autoplay.delay = SPEED - SLIDE_CHANGE_DURATION;
        changeStatusClass(s.realIndex);
      }
    },
    init: function () {
      const slides = document.querySelectorAll(".c-feature__card");
      slides.forEach((slide) => {
        slide.classList.add("changed");
      });
    },
    slideChangeTransitionStart: function () {
      const slides = document.querySelectorAll(".c-feature__card");
      slides.forEach((slide) => {
        slide.classList.add("changing");
        slide.classList.remove("changing");
      });
    },
    slideChangeTransitionEnd: function () {
      const slides = document.querySelectorAll(".c-feature__card");
      slides.forEach((slide) => {
        slide.classList.remove("changing");
        slide.classList.add("changing");
      });
    },
  },
};

// mv_swiper
const mvSwiperElems = document.querySelectorAll(".p-top-mv__swiper-slide");
const numberDisplay = document.querySelector(".p-top-mv__swiper-number-display__text");
const slideNavUnit = document.querySelector(".p-top-mv__swiper__nav-unit");
const copyElems = document.querySelectorAll(".p-top-mv__slide__copy");

const setAttributeMvCopy = (copyElems: NodeListOf<Element>, swiper: Swiper) => {
  copyElems.forEach((copyElem) => {
    copyElem.setAttribute("aria-hidden", "true");
  });
  copyElems[swiper.realIndex].setAttribute("aria-hidden", "false");
};

const mvSwiperOptions: SwiperOptions = {
  speed: MV_SLIDE_CHANGE_DURATION,
  slidesPerView: "auto",
  loop: isSingle(mvSlideLength),
  effect: "fade",
  fadeEffect: {
    crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
  },
  autoplay: {
    delay: MV_SPEED,
    disableOnInteraction: false,
  },
  // autoplay: false,
  pagination: {
    el: ".p-top-mv__swiper-pagination",
    clickable: false,
    type: "bullets",
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "<b class='c-swiper-progress-in'>" + "</b>" + "</span>";
    },
  },
  navigation: {
    nextEl: ".p-top-mv__swiper-button-next",
    prevEl: ".p-top-mv__swiper-button-prev",
  },
  on: {
    init: function (s) {
      slideNavUnit?.setAttribute("aria-hidden", "false");
      setAttributeMvCopy(copyElems, s);
    },
    slideChange: (s) => {
      if (mvSlide && numberDisplay) {
        mvSlide.params.autoplay.delay = MV_SPEED - MV_SLIDE_CHANGE_DURATION;
        numberDisplay.textContent = `0${String(s.realIndex + 1)} / 0${String(mvSwiperElems.length)}`;
      }
      setAttributeMvCopy(copyElems, s);
    },
  },
};

const featureSliderHandler = () => {
  featureSlide = new Swiper(".c-feature__body", swiperOptions);
  initProgressFeatureAnimationDuration(SPEED);
};

const mvSliderHandler = () => {
  // init
  if (numberDisplay) numberDisplay.textContent = `01 / 0${String(mvSwiperElems.length)}`;
  const targetElement = document.querySelector(".p-top-mv__swiper");

  // 監視するDOM要素の初期状態を保存する
  const initialTargetState = (targetElement as HTMLElement).innerHTML;

  const checkTargetState = () => {
    if ((targetElement as HTMLElement).innerHTML === initialTargetState) {
      mvSlide = new Swiper(".p-top-mv__swiper", mvSwiperOptions);
    } else {
      checkTargetState();
    }
  };

  checkTargetState();

  initProgressAnimationDuration(MV_SPEED);
};

///order用swiper記述

const modalSlideHandler = () => {
  const openModalBtns = document.querySelectorAll(".p-order-suit-style__btn");
  const swiperModal = new Swiper(".modal-swiper", {
    loop: isSingle(orderModalSlideLength),
    navigation: {
      nextEl: ".p-order-suit-style-modal-next",
      prevEl: ".p-order-suit-style-modal-prev",
    },
  });
  openModalBtns.forEach((openModalBtn) => {
    openModalBtn.addEventListener("click", () => {
      // data-slide-indexに設定したスライド番号を取得
      const modalIndex = (openModalBtn as HTMLElement).dataset.slideIndex;
      swiperModal.slideTo(modalIndex as any);
    });
  });
};

/**
 * order-use-block ブロックに対応するようクラスをjs用に変更した_240418
 */
const useSlideHandler = () => {
  new Swiper(".js-order-use__swiper", {
    slidesPerView: "auto",
    allowTouchMove: false,

    navigation: {
      nextEl: ".js-order-use-next",
      prevEl: ".js-order-use-prev",
    },
  });
};

const styleSlideHandler = () => {
  new Swiper(".p-order-style__swiper", {
    slidesPerView: "auto",
    loop: isSingle(orderSuitStyleSlideLength),
    speed: 6000,
    autoplay: {
      delay: 1,
    },
  });
};

const supportSlideHandler = () => {
  new Swiper(".c-order-support__slide", {
    slidesPerView: "auto",
    loop: true,
    speed: 6000,
    autoplay: {
      delay: 1,
    },
    breakpoints: {
      // 768px以上の場合
      768: {
        loop: false,
        autoplay: false,
      },
    },
  });
};

const freshersSlideHandler = () => {
  new Swiper(".p-freshers-item__swiper-wrapper", {
    slidesPerView: "auto",
    navigation: {
      nextEl: ".p-freshers-item-next",
      prevEl: ".p-freshers-item-prev",
    },
    breakpoints: {
      // 768px以上の場合
      768: {
        allowTouchMove: false,
      },
    },
  });
};

const mafmdSlideHandler = () => {
  new Swiper(".p-maf-possible__swiper-md", {
    slidesPerView: "auto",
    navigation: {
      nextEl: ".p-maf-possible-md-next",
      prevEl: ".p-maf-possible-md-prev",
    },
    breakpoints: {
      // 768px以上の場合
      768: {
        allowTouchMove: false,
      },
    },
  });
};

const mafsmSlideHandler = () => {
  new Swiper(".p-maf-possible__swiper-sm", {
    slidesPerView: "auto",
    navigation: {
      nextEl: ".p-maf-possible-sm-next",
      prevEl: ".p-maf-possible-sm-prev",
    },
    breakpoints: {
      // 768px以上の場合
      768: {
        allowTouchMove: false,
      },
    },
  });
};

const topicNewsSlider = () => {
  if (document.querySelector(".l-header__announce-bar")) {
    new Swiper(".l-header__announce-bar", {
      direction: "vertical",
      loop: isSingle(newsTickerLength),
      speed: 1000,
      allowTouchMove: false,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    });
  }
};

const featureCommonMdSlideHandler = () => {
  new Swiper(".c-feature-possible__swiper-md", {
    slidesPerView: "auto",
    navigation: {
      nextEl: ".c-feature-possible-md-next",
      prevEl: ".c-feature-possible-md-prev",
    },
    breakpoints: {
      // 768px以上の場合
      768: {
        allowTouchMove: false,
      },
    },
  });
};

const featureCommonSmSlideHandler = () => {
  new Swiper(".c-feature-possible__swiper-sm", {
    slidesPerView: "auto",
    navigation: {
      nextEl: ".c-feature-possible-sm-next",
      prevEl: ".c-feature-possible-sm-prev",
    },
    breakpoints: {
      // 768px以上の場合
      768: {
        allowTouchMove: false,
      },
    },
  });
};

export {
  featureSliderHandler,
  mvSliderHandler,
  modalSlideHandler,
  useSlideHandler,
  styleSlideHandler,
  supportSlideHandler,
  freshersSlideHandler,
  topicNewsSlider,
  mafmdSlideHandler,
  mafsmSlideHandler,
  featureCommonMdSlideHandler,
  featureCommonSmSlideHandler,
};
