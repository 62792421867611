function commonSpecialHandler() {
  document.addEventListener("DOMContentLoaded", () => {
    const goTopButton = document.getElementById("goTop");

    if (goTopButton) {
      goTopButton.addEventListener("click", () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
    }
  });
}

export { commonSpecialHandler };
