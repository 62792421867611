import "./lib/smoothScroll";
import "regenerator-runtime/runtime";
import {
  featureSliderHandler,
  mvSliderHandler,
  modalSlideHandler,
  useSlideHandler,
  styleSlideHandler,
  supportSlideHandler,
  freshersSlideHandler,
  mafmdSlideHandler,
  mafsmSlideHandler,
  topicNewsSlider,
  featureCommonMdSlideHandler,
  featureCommonSmSlideHandler,
} from "./lib/swiper";
import { microModal } from "./lib/micromodal";
import { Top } from "./namespace/top";
import { Order, orderAccordion, orderAccordionHelper, orderDatawidth } from "./namespace/order";
import { header } from "./namespace/header";
import Blogs from "./namespace/blogs";
import Shop from "./namespace/shop";
import { Fabric } from "./namespace/order/fabric";
import { fadeAnimation } from "./util/fadeAnim";
import { commonFadein, commonTextunderfadein, commonTextfadein } from "./namespace/special/cashmerecoat/cashmerecoat";
import { tabSelect } from "./util";
import { currentLinkTab } from "./util/currentLinkTab";
import { officialSuits } from "./namespace/special/officialSuits/officialSuits";
import { anniversary25th } from "./namespace/special/anniversary25th/anniversary25th";
import { commonSpecialHandler } from "./namespace/special/common/common";

export const body = document.getElementsByTagName("body")[0];

!document.querySelector(".p-top") && false;

/**
 * session storageにhasVisitedHomePageがない場合は、初回アクセスとして扱う
 */
const loaderElement = document.querySelector(".c-loading");

if (!sessionStorage.getItem("hasVisitedHomePage")) {
  sessionStorage.setItem("hasVisitedHomePage", "true");
  window.addEventListener("load", () => {
    if (document.querySelector(".p-top")) {
      Top();
      mvSliderHandler();
    }
  });
} else {
  window.addEventListener("load", () => {
    // 二回目以降のアクセスは、ローダーを表示しない
    loaderElement?.remove();
    if (document.querySelector(".p-top")) {
      Top();
      mvSliderHandler();
    }
  });
}

if (document.querySelector(".p-order")) {
}

if (document.querySelector(".p-shop")) {
  Shop();
}

if (document.querySelector(".p-blogs")) {
  Blogs();
}

if (document.querySelector(".p-order-fabric")) {
  Fabric();
}

if (document.querySelector(".cashmereCoat")) {
  commonFadein();
  commonTextunderfadein();
  commonTextfadein();
}

if (document.querySelector(".p-freshers")) {
  freshersSlideHandler();
}

if (document.querySelector(".p-maf")) {
  mafmdSlideHandler();
  mafsmSlideHandler();
}
if (document.querySelector(".c-feature")) {
  featureCommonMdSlideHandler();
  featureCommonSmSlideHandler();
}
if (document.querySelector(".p-news")) {
}

if (document.querySelector(".p-officialSuit--2024")) {
  officialSuits();
}
if (document.querySelector(".p-25thanniversary")) {
  anniversary25th();
}
if (document.querySelector(".p-special-body")) {
  commonSpecialHandler();
}

Order();
orderAccordion();
orderAccordionHelper();
microModal();
modalSlideHandler();
useSlideHandler();
styleSlideHandler();
supportSlideHandler();
orderDatawidth();
topicNewsSlider();
setTimeout(() => {
  featureSliderHandler();
}, 850);

header();
fadeAnimation();
tabSelect();
currentLinkTab();
