/**
 * フェードイン
 * https://www.w3.org/TR/2014/REC-wai-aria-20140320/states_and_properties#aria-expanded
 */

function commonFadein(): void {
  const target = document.querySelectorAll(".js-fadeIn");
  const targetArray = Array.prototype.slice.call(target);

  const options = {
    root: null,
    rootMargin: "-30% 0px",
    threshold: 0,
  };

  const observer = new IntersectionObserver(addActiveClass, options);
  targetArray.forEach(function (tgt) {
    observer.observe(tgt);
  });

  function addActiveClass(entries: any) {
    entries.forEach(function (entry: any, i: any) {
      const target = entry.target;
      if (entry.isIntersecting && !target.classList.contains("is-active")) {
        target.classList.add("is-active");
      }
    });
  }
}


/**
 * 左から右のテキストフェードイン
 */

function commonTextfadein(): void {
  const target = document.querySelectorAll(".leftAnime");
  const targetArray = Array.prototype.slice.call(target);

  const options = {
    root: null,
    rootMargin: "-30% 0px",
    threshold: 0,
  };

  const observer = new IntersectionObserver(addActiveClass, options);
  targetArray.forEach(function (tgt) {
    observer.observe(tgt);
  });

  function addActiveClass(entries: any) {
    entries.forEach(function (entry: any, i: any) {
      const target = entry.target;
      if (entry.isIntersecting && !target.classList.contains("slideAnimeLeftRight")) {
        target.classList.add("slideAnimeLeftRight");
        target.children[0].classList.add("slideAnimeRightLeft");
      }
    });
  }
}

/**
 * テキストフェードイン
 */

function commonTextunderfadein(): void {
  const target = document.querySelectorAll(".blurTrigger");
  const targetArray = Array.prototype.slice.call(target);

  const options = {
    root: null,
    rootMargin: "-30% 0px",
    threshold: 0,
  };

  const observer = new IntersectionObserver(addActiveClass, options);
  targetArray.forEach(function (tgt) {
    observer.observe(tgt);
  });

  function addActiveClass(entries: any) {
    entries.forEach(function (entry: any, i: any) {
      const target = entry.target;
      if (entry.isIntersecting && !target.classList.contains("blur")) {
        target.classList.add("blur");
      }
    });
  }
}

export { commonFadein, commonTextunderfadein, commonTextfadein };