import { getMediaQueryList } from "../util/getMediaQueryList";
import { body } from "../main";

const header = () => {
  const headerMenuNav = document.getElementById("js-header-menu-nav");
  const headerElem = document.getElementById("js-header");
  const headerBtn = document.getElementById("js-header-btn");
  const headerBottomMenu = document.getElementById("js-header-bottom-menu");
  const bottomHeaderBtn = document.getElementById("js-bottom-btn");
  const bottomHeaderBtnSp = document.getElementById("js-header-btn-sp");
  const bottomHeaderClose = document.getElementById("js-header-close");
  const jsBottomTriggerElem = document.getElementById("js-bottom-trigger");
  const wrapperArea = document.getElementById("js-wrapper");
  const banner25th = document.getElementById("js-25th-bnr");
  const banner25thCloseBtn = document.getElementById("js-25th-bnr-close");

  // tabindex 選択の可否を制御
  const toggleTabIndex = (): void => {
    const childLinkList = document.querySelectorAll<HTMLAnchorElement>(`#js-header-menu-nav * a`);
    childLinkList.forEach((childLinkItem) => {
      const tabIdx = childLinkItem.getAttribute("tabindex");
      tabIdx === "-1" ? childLinkItem.setAttribute("tabindex", "0") : childLinkItem.setAttribute("tabindex", "-1");
    });
  };

  if (headerBtn && headerMenuNav) {
    headerBtn.addEventListener("click", () => {
      if (headerMenuNav.getAttribute("aria-hidden") == String(true)) {
        headerMenuNav.setAttribute("aria-hidden", String(false));
      } else {
        headerMenuNav.setAttribute("aria-hidden", String(true));
      }

      if (wrapperArea?.getAttribute("aria-hidden") == String(true)) {
        wrapperArea?.setAttribute("aria-hidden", String(false));
      } else {
        wrapperArea?.setAttribute("aria-hidden", String(true));
      }
      body.classList.add("is-scroll-lock");
      toggleTabIndex();
    });
  }

  if (bottomHeaderBtn && headerMenuNav) {
    bottomHeaderBtn.addEventListener("click", () => {
      if (headerMenuNav.getAttribute("aria-hidden") == String(true)) {
        headerMenuNav.setAttribute("aria-hidden", String(false));
      } else {
        headerMenuNav.setAttribute("aria-hidden", String(true));
      }

      if (wrapperArea?.getAttribute("aria-hidden") == String(true)) {
        wrapperArea?.setAttribute("aria-hidden", String(false));
      } else {
        wrapperArea?.setAttribute("aria-hidden", String(true));
      }
      body.classList.add("is-scroll-lock");
      toggleTabIndex();
    });
  }

  if (bottomHeaderClose && headerMenuNav) {
    bottomHeaderClose.addEventListener("click", () => {
      if (headerMenuNav.getAttribute("aria-hidden") == String(false)) {
        headerMenuNav.setAttribute("aria-hidden", String(true));
      } else {
        headerMenuNav.setAttribute("aria-hidden", String(false));
      }

      if (wrapperArea?.getAttribute("aria-hidden") == String(false)) {
        wrapperArea?.setAttribute("aria-hidden", String(true));
      } else {
        wrapperArea?.setAttribute("aria-hidden", String(false));
      }
      body.classList.remove("is-scroll-lock");
      toggleTabIndex();
    });
  }

  if (wrapperArea && headerMenuNav) {
    wrapperArea.addEventListener("click", () => {
      if (headerMenuNav.getAttribute("aria-hidden") == String(false)) {
        headerMenuNav.setAttribute("aria-hidden", String(true));
      } else {
        headerMenuNav.setAttribute("aria-hidden", String(false));
      }

      if (wrapperArea?.getAttribute("aria-hidden") == String(false)) {
        wrapperArea?.setAttribute("aria-hidden", String(true));
      } else {
        wrapperArea?.setAttribute("aria-hidden", String(false));
      }
      body.classList.remove("is-scroll-lock");
      toggleTabIndex();
    });
  }

  if (bottomHeaderBtnSp && headerMenuNav) {
    bottomHeaderBtnSp.addEventListener("click", () => {
      if (headerMenuNav.getAttribute("aria-hidden") == String(false)) {
        headerMenuNav.setAttribute("aria-hidden", String(true));
      } else {
        headerMenuNav.setAttribute("aria-hidden", String(false));
      }

      if (wrapperArea?.getAttribute("aria-hidden") == String(false)) {
        wrapperArea?.setAttribute("aria-hidden", String(true));
      } else {
        wrapperArea?.setAttribute("aria-hidden", String(false));
      }
      body.classList.remove("is-scroll-lock");
      toggleTabIndex();
    });
  }

  const addBannerCloseListener = (closeBtn: HTMLElement, banner: HTMLElement) => {
    closeBtn.addEventListener("click", () => {
      banner.style.display = "none";
    });
  };
  
  if (banner25thCloseBtn && banner25th) {
    addBannerCloseListener(banner25thCloseBtn, banner25th);
  }

  const observerTarget = function (entries: any) {
    if (headerBottomMenu && jsBottomTriggerElem) {
      entries.forEach((entry: any) => {
        if (!entry.isIntersecting) {
          jsBottomTriggerElem.classList.add("is-through");
          headerBottomMenu.setAttribute("aria-hidden", String(false));
          if (banner25th !== null) {
            banner25th.setAttribute("aria-hidden", String(false));
          }
        } else {
          headerBottomMenu.setAttribute("aria-hidden", String(true));
          if (banner25th !== null) {
            banner25th.setAttribute("aria-hidden", String(true));
          }
          jsBottomTriggerElem.classList.remove("is-through");
        }
      });
    }
  };

  const ioOptions: any = {
    rootMargin: "0px 0px",
    threshold: "1",
  };

  // 1. 変数mqlにMediaQueryListを格納
  const mql = window.matchMedia("(min-width: 768px)");
  const ioBottomMenu = new IntersectionObserver(observerTarget, ioOptions);

  // 画面ロードに条件（PCサイズ以上）に一致したらボトムメニューを表示する。一致しない場合は、監視を実行する。
  if (mql.matches) {
    headerBottomMenu?.setAttribute("aria-hidden", String(false));
    if (banner25th !== null) {
      banner25th.setAttribute("aria-hidden", String(false));
    }
  } else {
    jsBottomTriggerElem && ioBottomMenu.observe(jsBottomTriggerElem);
  }

  getMediaQueryList("md").addEventListener("change", (event) => {
    if (event.matches) {
      headerBottomMenu?.setAttribute("aria-hidden", String(false));
      if (banner25th !== null) {
        banner25th.setAttribute("aria-hidden", String(false));
      }

      jsBottomTriggerElem && ioBottomMenu.unobserve(jsBottomTriggerElem);
    } else {
      jsBottomTriggerElem && ioBottomMenu.observe(jsBottomTriggerElem);
    }
  });

  // トップページにだけ背景色つける
  window.location.pathname === "/" && headerElem?.classList.add("is-top");
};

export { header };
