function fadeInElementsOnViewport(): void {
  const fadeInElements = document.querySelectorAll(".js-fadein");

  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };

  const observerCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("is-visible");
        observer.unobserve(entry.target); // 一度フェードインしたら監視を停止
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  fadeInElements.forEach((element) => {
    observer.observe(element);
  });
}

export { fadeInElementsOnViewport };
