import { body } from "../main";

const Blogs = (): void => {
  const openPopupBtn = document.getElementById("open-blogs-popup");
  const closePopupBtn = document.getElementById("close-blogs-popup");
  const popUpElem = document.getElementById("blogs-popup");

  popUpElem?.addEventListener("click", (event: any) => {
    if (event.target.closest("#js-search-modal") === null) {
      if (popUpElem?.getAttribute("aria-hidden") === "false") {
        popUpElem.setAttribute("aria-hidden", "true");
        body.classList.remove("is-scroll-lock");
      }
    }
  });

  const shopUnitElemList = document.querySelectorAll<HTMLElement>(".js-shop-unit");
  const navItemElemList = document.querySelectorAll<HTMLElement>(".js-nav-item");

  openPopupBtn?.addEventListener("click", () => {
    if (popUpElem?.getAttribute("aria-hidden") === "true") {
      popUpElem.setAttribute("aria-hidden", "false");
      body.classList.add("is-scroll-lock");
    }
  });

  closePopupBtn?.addEventListener("click", () => {
    if (popUpElem?.getAttribute("aria-hidden") === "false") {
      popUpElem.setAttribute("aria-hidden", "true");
      body.classList.remove("is-scroll-lock");
    }
  });

  const targetActiveElem = (navItem: HTMLElement) => {
    const navItemName = navItem.getAttribute("aria-controls");
    shopUnitElemList.forEach((shopUnitElem) => {
      const shopUnitName = shopUnitElem.getAttribute("id");
      if (navItemName === shopUnitName) {
        shopUnitElem.setAttribute("aria-hidden", "false");
        navItem.setAttribute("aria-selected", "true");
      }
      if (navItemName === "all") {
        shopUnitElem.setAttribute("aria-hidden", "false");
        navItem.setAttribute("aria-selected", "true");
      }
    });
  };

  const allInActiveElem = () => {
    navItemElemList.forEach((navItem) => {
      navItem.setAttribute("aria-selected", "false");
    });

    shopUnitElemList.forEach((shopUnitElem) => {
      shopUnitElem.setAttribute("aria-hidden", "true");
    });
  };

  navItemElemList.forEach((navItem) => {
    navItem.addEventListener("click", () => {
      // いったん全てを非アクティブ
      allInActiveElem();
      targetActiveElem(navItem);
    });
  });
};

export default Blogs;
